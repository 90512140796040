import './scss/blocos_destaque.scss';

import Vue from 'vue';
import SvgIcon from '../../store-customization-v2/components/SvgIcon.vue';

const listaBlocos = document.getElementsByClassName('payt-block-destaque-wrapper');
for (let blocoDestaque of listaBlocos) {
    createVueBlocoDestaqueApp(blocoDestaque);
}

function createVueBlocoDestaqueApp(node) {
    if(!node) {
        return;
    }
    new Vue({
        el: node,
        components: { SvgIcon },
    });    
}
